<template>
  <v-app>
    <v-app-bar
      app
      color="#EFE0CC"
      dark
      height="80"
      elevation="0"
      class="px-0 mx-0"
      fixed
      v-if="!isMobile"
    >
      <v-container fluid class="py-0">
        <v-row style="height: 80px;" align="center">
          <v-col cols="auto" class="py-0">
            <router-link to="/">
              <v-img src="@/assets/img/logo.png" contain max-width="207"></v-img>
            </router-link>
          </v-col>
          <v-col class="text-right" align-self="center">
            <router-link class="druk-wide-bold menu-link not-decorated" to="/menu" style="color: #232222">MENÚ</router-link>
          </v-col>
          <v-col cols="auto" style="background-color: #232222;" class="py-0">
            <div class="d-flex align-center" style="height: 80px;">
              <a href="whatsapp://send?phone=+5218132327194&abid=+5218132327194" class="druk-wide-bold white--text menu-link not-decorated">ORDENA AHORA</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar

      color="#EFE0CC"
      dark
      height="60"
      elevation="0"
      class="px-0 mx-0"
      v-if="isMobile"
    >
      <v-container fluid class="py-0">
        <v-row style="height: 60px;" align="center">
          <v-col cols="6" offset="3" class="py-0 text-center">
            <router-link to="/">
              <v-img src="@/assets/img/icono.svg" max-width="80" class="mx-auto"></v-img>
            </router-link>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-icon color="black" @click="menuOpen = !menuOpen">mdi-menu</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="isOpen"
      app
      color="#EFE0CC"
    >
      <v-list
        nav
        dense
      >
        <v-list-item to="/menu">
          Menu
        </v-list-item>
        <v-list-item href="https://www.facebook.com/huesodevacabbq">
          Ordena Ahora
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
    menuOpen: false
  }),
  computed: {
    isMobile: function () {
      return (this.$vuetify.breakpoint.mobile)
    },
    isOpen: function () {
      return (this.menuOpen && this.isMobile)
    }
  },
  watch: {
    $route: () => {
      this.menuOpen = false
    }
  }
}
</script>
